import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import organizationAPI from 'api/api';
import { EmptyState } from 'components/CustomMUI';
import LoadingOverlay from 'components/LoadingOverlay';
import { useLoggedUser } from 'contexts/UserContext';
import { useApi } from 'hooks';
import useNotification from 'hooks/useNotification';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import usePersonaStore from 'store/personaStore';
import useNotificationStore from 'store/useNotificationStore';
import useStore from 'store/useStore';

const AttributeRow = ({ label, value }) => (
  <Box
    sx={{
      display: 'flex',

      mb: 1,
      alignItems: 'center',
    }}
  >
    <Typography
      sx={{
        color: '#666',
        fontSize: '0.9rem',

        width: '50%',
        textAlign: 'start',
      }}
    >
      {label}
    </Typography>
    <Typography
      sx={{
        fontSize: '0.9rem',

        width: '50%',
        textAlign: 'start',
      }}
    >
      {value}
    </Typography>
  </Box>
);

const AssignmentBoard = () => {
  const { user } = useLoggedUser();
  const navigate = useNavigate();
  const location = useLocation();
  const assignmentId = location.pathname.split('/').pop();
  const userId = user._id;
  const { show, NotificationComponent } = useNotification();
  const { notification } = useNotificationStore();
  const { setAssignment } = useStore();
  const {
    clearPersonas,
    personas,
    setPersonas,
    clearSelectedPersona,
    selectPersona,
    selectedPersona,
    selectPersonaBehavior,
    clearPersonaBehavior,
  } = usePersonaStore();

  const { call: getAssignmentPersonas, isLoading: assignmentLoading } = useApi({
    fetcher: organizationAPI.getAssignmentPersonas,
    successMessage: 'Assignment details fetched successfully',
  });

  const { call: getPersonaById, isLoading: personaLoading } = useApi({
    fetcher: organizationAPI.getPersonaById,
  });

  useEffect(() => {
    clearPersonas();
    clearSelectedPersona();
    clearPersonaBehavior();
    const fetchAssignmentAndPersonas = async () => {
      try {
        const assignmentResponse = await getAssignmentPersonas({
          userId,
          assignmentId,
        });
        const originalCalls = assignmentResponse.data;

        const enhancedCalls = await Promise.all(
          originalCalls.map(async (call) => {
            try {
              const personaResponse = await getPersonaById(call.personaId);
              const personaData = personaResponse.data;

              return {
                ...call,
                assignedCallId: call._id,
                assignmentId: assignmentId,
                _id: call.personaId,
                avatar: personaData?.avatar || './user.jpg',
                company: personaData?.company || null,
                role: personaData?.role || null,
                gender: personaData?.gender || null,
                model: personaData?.model || null,
                coach: personaData?.coach || null,
                language: personaData?.language || null,
                type: personaData?.type || null,
              };
            } catch (error) {
              console.error(`Error fetching persona ${call.personaId}:`, error);
              return {
                ...call,
                avatar: './user.jpg',
                company: null,
                role: null,
              };
            }
          })
        );

        setPersonas(enhancedCalls);
      } catch (error) {
        console.error('Error fetching assignment:', error);
      }
    };

    fetchAssignmentAndPersonas();
    setAssignment(userId, assignmentId);
  }, []);

  useEffect(() => {
    if (notification) {
      show(notification.message, notification.type);
    }
  }, [notification, show]);

  const handleSelectedPersona = (_persona) => {
    selectPersona(_persona._id);
    selectPersonaBehavior(_persona.mood, _persona.tone, _persona.difficulty);
  };

  const handleRedirectToCallPage = (personaId) => {
    navigate(`/airoleplaycall/ongoingcall/${personaId}`);
  };

  const centerItems = personas?.length <= 3;
  const loading = assignmentLoading || personaLoading;

  return (
    <Container maxWidth="xl" sx={{ p: 4, bgcolor: '#fff', height: '100vh' }}>
      {loading && <LoadingOverlay />}
      {notification && !loading ? (
        <NotificationComponent
          notification={notification.message}
          type={notification.type}
        />
      ) : null}
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
        <Typography
          variant="h4"
          sx={{
            color: '#243030',
            fontFamily: 'Lexend',
            fontSize: '28px',
            fontWeight: 300,
            letterSpacing: '-0.087px',
          }}
        >
          View Assignment: {user.firstName} {user.lastName}
        </Typography>
        <Box display={'flex'} alignItems={'center'} gap={2}>
          <Button
            startIcon={<ArrowBackIosIcon fontSize="small" />}
            sx={{
              border: '1px solid #00706C',
              padding: '6px 16px',
              color: '#00706C',
              bgcolor: '#fff',
              '&:hover': { bgcolor: '#DFFCFBFF' },
            }}
            onClick={() => navigate(-1)}
          >
            Return
          </Button>
          {/* <Button
            startIcon={<SendIcon />}
            sx={{
              border: '1px solid #00706C',
              padding: '6px 16px',
              color: '#fff',
              bgcolor: '#00706C',
              '&:hover': { bgcolor: '#147C79FF' },
            }}
          >
            Proceed To Next Call
          </Button> */}
        </Box>
      </Box>
      <Divider sx={{ border: '1px solid #dae8e7', my: '10px', mb: '30px' }} />

      {personas ? (
        <Grid
          container
          spacing={3}
          justifyContent={centerItems ? 'center' : 'flex-start'}
        >
          {personas.map((assignment, index) => (
            <Grid
              item
              xs={12}
              sm={centerItems ? 4 : 6}
              md={centerItems ? 4 : 4}
              lg={centerItems ? 2.4 : 2.4}
              key={index}
            >
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  border:
                    selectedPersona?._id === assignment.personaId &&
                    !assignment.callCompleted
                      ? '2px solid #0DDADAFF'
                      : '1px solid #ddd',
                  borderRadius: 2,
                  '&:hover': {
                    boxShadow: !assignment.callCompleted && 3,
                    border: !assignment.callCompleted && '2px solid #09C7C7FF',
                  },
                }}
                onClick={() => {
                  handleSelectedPersona(assignment);
                  if (selectedPersona?._id === assignment._id) {
                    clearSelectedPersona();
                  }
                }}
              >
                <CardContent
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    flexGrow: 1,
                  }}
                >
                  <Avatar
                    src={assignment.avatar || '/user.jpg'}
                    sx={{
                      width: 80,
                      height: 80,
                      mb: 2,
                    }}
                  />
                  <Typography
                    variant="h6"
                    sx={{ mb: 1, fontWeight: 500, fontSize: '16px' }}
                  >
                    {assignment.personaName}
                  </Typography>
                  <Typography
                    sx={{
                      mb: 3,
                      color: '#666',
                      fontSize: '0.9rem',
                      minHeight: '40px',
                    }}
                  >
                    {assignment.role} @ {assignment.company}
                  </Typography>

                  <Box sx={{ width: '100%', mb: 3 }}>
                    <AttributeRow label="Mood" value={assignment?.mood?.name} />
                    <AttributeRow label="Tone" value={assignment?.tone?.name} />
                    <AttributeRow
                      label="Difficulty"
                      value={assignment?.difficulty?.name}
                    />
                  </Box>

                  <Button
                    variant={
                      !assignment.callCompleted ? 'contained' : 'outlined'
                    }
                    fullWidth
                    sx={{
                      mt: 'auto',
                      textTransform: 'none',
                      backgroundColor: !assignment.callCompleted
                        ? '#008080'
                        : '#f5f5f5',
                      color: !assignment.callCompleted ? 'white' : '#666',
                      '&:hover': {
                        backgroundColor: !assignment.callCompleted
                          ? '#006666'
                          : '#eeeeee',
                      },
                    }}
                    disabled={assignment.callCompleted}
                    onClick={() => {
                      handleRedirectToCallPage(assignment._id);
                      handleSelectedPersona(assignment);
                    }}
                  >
                    {assignment.callCompleted ? 'Call Completed' : 'Start Call'}
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      ) : (
        <EmptyState />
      )}
    </Container>
  );
};
export default AssignmentBoard;
