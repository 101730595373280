import { Box, Divider, Typography } from '@mui/material';
import { FormField } from 'pages/Settings/Tabs/common';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDialogStore } from 'store/useStore';

export const ModuleDetails = () => {
  const editDialogState = useDialogStore((state) => state.editDialog);
  const {
    register,
    formState: { errors },
    watch,
    setValue,
    trigger,
    reset,
  } = useFormContext();

  useEffect(() => {
    reset(editDialogState.data);
  }, [editDialogState.data]);

  const renderFormFields = () => {
    const infoFields = [
      { name: 'title', label: 'Module Title', type: 'text' },
      { name: 'category', label: 'Category', type: 'text' },
      { name: 'playbookType', label: 'Playbook Type', type: 'text' },
      {
        name: 'viewPlaybookList',
        label: 'Ventures Who Can See This Playbook',
        type: 'multiSelect',
        autoComplete: true,
      },
      {
        name: 'description',
        label: 'Description',
        type: 'text',
        multiline: true,
      },
      { name: 'documentTemplate', label: 'Document Template', type: 'text' },
      {
        name: 'spaceOwnership',
        label: 'Space Ownership',
        type: 'text',
      },
    ];

    const settingsFields = [
      {
        name: 'course',
        label: 'Course',
        type: 'multiSelect',
        autoComplete: true,
      },
      {
        name: 'deliveryMethod',
        label: 'Delivery Method',
        type: 'multiSelect',
        autoComplete: true,
      },
      {
        name: 'industryFocus',
        label: 'Industry Focus',
        type: 'multiSelect',
        autoComplete: true,
      },
      {
        name: 'location',
        label: 'Location',
        type: 'multiSelect',
        autoComplete: true,
      },
      {
        name: 'pillar',
        label: 'Pillar',
        type: 'multiSelect',
        autoComplete: true,
      },
      {
        name: 'stages',
        label: 'Stage of Business',
        type: 'multiSelect',
        autoComplete: true,
      },
    ];

    return (
      <>
        {/* <AvatarSelector title={'Module Cover'} /> */}
        {infoFields.map((item) => (
          <FormField
            //loading={srLoading || personasLoading}
            formType="ADD"
            key={item.name}
            field={item}
            register={register}
            errors={errors}
            watch={watch}
            hookFormUpdater={setValue}
            hookFormTrigger={trigger}
          />
        ))}
        <Typography variant="button">Module Settings</Typography>
        <Divider sx={{ borderColor: 'primary.main' }} />
        {settingsFields.map((item) => (
          <FormField
            //loading={srLoading || personasLoading}
            formType="ADD"
            key={item.name}
            field={item}
            register={register}
            errors={errors}
            watch={watch}
            hookFormUpdater={setValue}
            hookFormTrigger={trigger}
          />
        ))}
      </>
    );
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      {renderFormFields()}
    </Box>
  );
};
