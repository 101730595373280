import { Visibility } from '@mui/icons-material';
import { Button, Checkbox, Chip, Stack, Typography } from '@mui/material';
import apis from 'api/api';
import { DataTable, TableSkeleton } from 'components/Table';
import { useApi } from 'hooks';
import { useTableSearch } from 'pages/Settings/context';
import { useEffect, useState } from 'react';
import { useAuthStore, useDialogStore, useItemStore } from 'store';
import { StoreKeys } from 'utils';
import { getOriginalZonedDate } from 'utils/date';
import { EditDialog as ModuleEditDialog } from '../Module/common/EditDialog';

const ModuleSelectionTableColumns = [
  { id: 'title', label: 'Module Name' },
  { id: 'category', label: 'Category' },
  { id: 'playbookType', label: 'Playbook Type' },
  { id: 'createdAt', label: 'Created On' },
  { id: 'status', label: 'Status' },
  { id: 'actions', label: 'Actions' },
];
const DialogOpenKey = 'ModuleDetails';

export const ModuleSelection = ({
  modules = [],
  selected = [],
  aiRecommended = false,
}) => {
  const user = useAuthStore((state) => state.loggedUser);
  const itemStore = useItemStore((state) => state.items);
  const addItemToStore = useItemStore((state) => state.addItem);
  const dialogState = useDialogStore((state) => state.editDialog);
  const setEditDialog = useDialogStore((state) => state.setEditDialog);

  const [selectedModules, setSelectedModules] = useState(selected);

  const handleModuleChange = (moduleId, isChecked) => {
    setSelectedModules((prev) => {
      if (isChecked) {
        if (!prev.some((mod) => mod.moduleId === moduleId)) {
          return [...prev, { moduleId, sections: [] }];
        }
      } else {
        return prev.filter((mod) => mod.moduleId !== moduleId);
      }
      return prev;
    });
  };

  const handleSectionChange = (moduleId, sectionId, isChecked) => {
    setSelectedModules((prev) => {
      const moduleIndex = prev.findIndex((mod) => mod.moduleId === moduleId);
      if (moduleIndex > -1) {
        const updatedModules = [...prev];
        const module = updatedModules[moduleIndex];

        if (isChecked) {
          if (!module.sections.includes(sectionId)) {
            module.sections.push(sectionId);
          }
        } else {
          module.sections = module.sections.filter(
            (section) => section !== sectionId
          );
          if (module.sections.length === 0) {
            updatedModules.splice(moduleIndex, 1);
          }
        }
        return updatedModules;
      } else {
        return [...prev, { moduleId, sections: [sectionId] }];
      }
    });
  };

  const { call: fetchModules, isLoading: isFetching } = useApi({
    fetcher: () => {
      return apis.getModuleList({ organizationId: user.organization });
    },
    onSuccess: (updatedData) => {
      addItemToStore({ key: StoreKeys.MODULES, value: updatedData });
    },
  });

  const searchFields = ['title'];
  const { setSearchTerm, filteredData } = useTableSearch({
    data: modules?.length
      ? modules
      : itemStore?.modules?.length
      ? itemStore?.modules
      : [],
    searchFields,
    filter: (item) => {
      if (aiRecommended && selected.length) {
        return item._id === selected[0].moduleId;
      }
      return item.status !== 'archive';
    },
  });

  useEffect(() => {
    if (!modules.length) fetchModules();
  }, []);

  useEffect(() => {
    if (selectedModules.length && !aiRecommended) {
      addItemToStore({ key: 'selectedModules', value: selectedModules });
    }
  }, [selectedModules]);

  return isFetching ? (
    <TableSkeleton column={ModuleSelectionTableColumns} />
  ) : (
    <>
      <DataTable
        columns={ModuleSelectionTableColumns}
        sort={true}
        collapseContent={(row) => (
          <div>
            <strong>Details:</strong> {row.title}
          </div>
        )}
        data={filteredData?.map((item) => {
          const isItemSelected = selectedModules.some(
            (module) => module.moduleId === item._id
          );

          return {
            ...item,
            title: (
              <>
                <Checkbox
                  disabled={aiRecommended}
                  size="xs"
                  color="primary"
                  checked={isItemSelected}
                  onClick={(e) =>
                    handleModuleChange(item._id, e.target.checked)
                  }
                />
                {item.title}
              </>
            ),
            actions: (
              <Button
                onClick={() => {
                  setEditDialog({
                    isOpen: true,
                    data: item,
                    forItem: DialogOpenKey,
                  });
                }}
                startIcon={<Visibility />}
              >
                View
              </Button>
            ),
            createdAt: item.createdAt
              ? getOriginalZonedDate({
                  date: item.createdAt,
                })
              : '-',
            status: (
              <Chip
                label={item.status}
                variant="outlined"
                size="small"
                color={item.status === 'ACCEPTED' ? 'success' : 'info'}
              />
            ),
            subRows: [
              ...(item?.sections?.map((section, index) => {
                const isSectionSelected = selectedModules.some(
                  (module) =>
                    module.moduleId === item._id &&
                    module?.sections?.some(
                      (sectionId) => sectionId === section._id
                    )
                );

                return {
                  title: (
                    <Stack
                      direction="row"
                      spacing={1}
                      sx={{ alignItems: 'center', marginLeft: '20px' }}
                    >
                      <Checkbox
                        key={section._id}
                        size="xs"
                        color="primary"
                        checked={isSectionSelected}
                        disabled={aiRecommended}
                        onClick={(e) =>
                          handleSectionChange(
                            item._id,
                            section._id,
                            e.target.checked
                          )
                        }
                      />
                      <Typography variant="body2">
                        {`Submodule ${index + 1}: ${section.title}`}
                      </Typography>
                    </Stack>
                  ),
                  actions: (
                    <Button
                      onClick={() => {
                        setEditDialog({
                          isOpen: true,
                          data: item,
                          forItem: DialogOpenKey,
                        });
                      }}
                      startIcon={<Visibility />}
                    >
                      View
                    </Button>
                  ),
                };
              }) || []),
            ],
          };
        })}
      />
      <ModuleEditDialog forItem={DialogOpenKey} />
    </>
  );
};
